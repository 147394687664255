<!--TODO доделать post-->
<template>
  <div class="popup-add-agencies">
    <div class="popup-add-agencies__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''">
        <p class="popup-add-agencies__title">Добавьте организацию</p>
        <p class="popup-add-agencies__description">У вас есть вопросы и предложения? Оставьте
          свое сообщение и наши менеджеры ответят вам на почту.</p>
        <SelectComponent id="agencyCity"
                         label="Город"
                         itemKey="name"
                         :index="agencyCity"
                         v-on:change-index="changeIndex"
                         :array="$cities.data"/>
        <InputComponent id="agenciesTitle"
                        label="Название организации"
                        type="text"
                        :disabled="this.$popup.additions.id"
                        v-model="agenciesTitle"/>
        <InputComponent id="agenciesAddress"
                        label="Адрес организации"
                        type="text"
                        :disabled="this.$popup.additions.id"
                        v-model="agenciesAddress"/>
        <InputComponent id="agenciesPhone"
                        label="Телефон"
                        type="text"
                        :disabled="this.$popup.additions.id"
                        v-model="agenciesPhone"/>
        <InputComponent id="agenciesWebSite"
                        label="Веб-сайт"
                        type="text"
                        :disabled="this.$popup.additions.id"
                        v-model="agenciesWebSite"/>
        <InputComponent id="agenciesEmail"
                        label="Электронная почта"
                        type="text"
                        :disabled="this.$popup.additions.id"
                        v-model="agenciesEmail"/>
        <InputComponent id="agenciesDescription"
                        v-model="agenciesDescription"
                        label="Описание"
                        type="text"
                        maxChars="700"
                        multiline/>
        <div>
          <Button color="green"
                  v-on:click.native="addAgencies">Добавить организацию
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PopupAddAgencies',
  data() {
    return {
      agencyCity: 3, // дефолтный город на боевой №3 !!!!
      agenciesTitle: '',
      agenciesAddress: '',
      agenciesPhone: '',
      agenciesWebSite: '',
      agenciesEmail: '',
      agenciesDescription: '',
    };
  },
  methods: {
    changeIndex(id) {
      this.agencyCity = id;
    },
    previewsToString() {
      let string = '';
      this.previews.forEach((item, i, array) => {
        string += `${i === 0 ? '[' : ''}"${item}"${i !== array.length - 1 ? ',' : ']'}`;
      });
      return string;
    },
    addAgencies() {
      let agencies = '?';
      if (this.agenciesTitle) agencies += `&title=${this.agenciesTitle}`;
      if (this.agenciesAddress) agencies += `&address=${this.agenciesAddress}`;
      if (this.agenciesPhone) agencies += `&phone=${this.agenciesPhone}`;
      if (this.agencyCity) agencies += `&city_id=${this.agencyCity}`;
      if (this.agenciesWebSite) agencies += `&website=${this.agenciesWebSite}`;
      if (this.agenciesEmail) agencies += `&email=${this.agenciesEmail}`;
      if (this.agenciesDescription) agencies += `&description=${this.agenciesDescription}`;
      if (this.$popup.additions && this.$popup.additions.id) {
        this.$putAddAgencies(agencies, this.$popup.additions.id);
      } else {
        this.$postAddAgencies(agencies);
      }
      this.agenciesTitle = '';
      this.agenciesAddress = '';
      this.agenciesPhone = '';
      this.agenciesWebSite = '';
      this.agenciesEmail = '';
      this.agenciesDescription = '';
    },
    removeFile(id) {
      this.previews.splice(id, 1);
    },
  },
  computed: {
    textareaCount() {
      return this.textareaMaxCount - this.agenciesDescription.length;
    },
  },
  created() {
    if (this.$popup.additions && this.$popup.additions.title) {
      this.agenciesTitle = this.$popup.additions.title;
    }
    if (this.$popup.additions && this.$popup.additions.address) {
      this.agenciesAddress = this.$popup.additions.address;
    }
    if (this.$popup.additions && this.$popup.additions.description) {
      this.agenciesDescription = this.$popup.additions.description;
    }
    if (this.$popup.additions && this.$popup.additions.phone) {
      this.agenciesPhone = this.$popup.additions.phone;
    }
    if (this.$popup.additions && this.$popup.additions.website) {
      this.agenciesWebSite = this.$popup.additions.website;
    }
    if (this.$popup.additions && this.$popup.additions.email) {
      this.agenciesEmail = this.$popup.additions.email;
    }
    if (this.$popup.additions && this.$popup.additions.city_id) {
      this.agencyCity = this.$popup.additions.city_id;
    }
  },
};
</script>
